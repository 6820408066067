import { Injectable } from '@angular/core';

import { HTTPErrorService } from './http-error.service';
import { ScannerSetting } from '../Models/ScannerSetting';

@Injectable()
export class ScannerSettingsService {

  public setting: ScannerSetting;
  public settings: ScannerSetting[] = [];

 


  constructor() {
    this.ReadAll();
  }

  public ReadAll() {
    var json;
    json = localStorage.getItem("ScannerSettings");

    if (json) {
      this.settings = JSON.parse(json);
    }
  }

  public Read(scannerName: string): ScannerSetting {
    var setting = new ScannerSetting(scannerName, 0, 0);

    //console.log("scannerName", scannerName);

    var settingsFiltered = this.settings.filter(function (x) {
      return x.ScannerName == scannerName;
    });

    //console.log("settingsFiltered", settingsFiltered);

    if (settingsFiltered.length > 0) {
      setting = settingsFiltered[0];
    }
    //console.log("settings", this.settings);

    return setting;
  }


  public Save(setting: ScannerSetting) {


    var settingsFiltered = this.settings.filter(function (x) {
      return x.ScannerName == setting.ScannerName;
    });


    if (settingsFiltered.length > 0) {
      settingsFiltered[0] = setting;
    }
    else {
      this.settings.push(setting);
    }

    var json = JSON.stringify(this.settings);
    localStorage.setItem("ScannerSettings", json);
  }




}
