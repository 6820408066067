import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


//import { AuthService } from '../Services/auth.service';
import { UserService } from '../Services/user.service';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userService: UserService){ }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var authToken = "";
    if (this.userService.user) {
      authToken = this.userService.user.AuthToken;
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`
      },
      withCredentials: true
    });


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          //console.log('error--->>>', error);
        }
        console.log('error--->>>', error);

        return throwError(error);
      }));


  }
}
