import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Guards/auth.guard';
import { MainComponent } from './Components/main/main.component';
import { LoginComponent } from './Components/login/login.component';
import { TransferBoxComponent } from './Components/transfer-box/transfer-box.component';
import { ShredLocationComponent } from './Components/shred-location/shred-location.component';
import { DestructionLogComponent } from './Components/destruction-log/destruction-log.component';

import { ScanStationModule } from './Components/scan-station/scan-station.module';

export const routes: Routes = [

  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'transfer-box', canActivate: [AuthGuard], component: TransferBoxComponent, pathMatch: 'full' },
  { path: 'shred-location', canActivate: [AuthGuard], component: ShredLocationComponent, pathMatch: 'full' },
  { path: 'destruction-log', canActivate: [AuthGuard], component: DestructionLogComponent, pathMatch: 'full' },

  { path: 'scanstation', canActivate: [AuthGuard], loadChildren: () => import('./Components/scan-station/scan-station.module').then(m => m.ScanStationModule) },

    //{
    //    path: '',
    //    canActivate: [AuthGuard],
    //    component: MainComponent,
    //    data: {
    //        title: 'Home'
    //    },
    //    children: [
    //        //{
    //        //    path: 'dashboard',
    //        //    loadChildren: './Components/dashboard/dashboard.module#DashboardModule'
    //        //},
    //        {
    //            path: 'scanstation',
    //            loadChildren: './Components/scan-station/scan-station.module#ScanStationModule'
    //        }
    //    ]
    //},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
