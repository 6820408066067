import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { HTTPErrorService } from './http-error.service';
import { Batch } from '../Models/Batch';
import { Box } from '../Models/Box';
import { BoxLocation } from '../Models/BoxLocation';
import { Assign } from '../Models/Assign';
import { AssignLocation } from '../Models/AssignLocation';


@Injectable()
export class BoxService {



  constructor(private http: HttpClient,
    private httpErrorService: HTTPErrorService) {
  }

  


  getBoxLocation(locationId: string): Observable<BoxLocation> {
    return this.http.post<any>('api/box/boxlocation/' + locationId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  getBoxesAtLocation(locationId: string): Observable<Box[]> {
    return this.http.post<any>('api/box/boxesatlocation/' + locationId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }



  setBoxLocation(boxNumber: string, locationId: string): Observable<any> {
    return this.http.post<any>('api/box/setboxlocation/' + boxNumber + '/' + locationId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }


  openAssigns(): Observable<Assign[]> {
    return this.http.get<any>('api/box/assigns/open')
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  } 

  startAssign(assign: Assign): Observable<number> {
    return this.http.post<any>('api/box/assign/start', assign)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  checkOutAssign(assign: Assign): Observable<Assign> {
    return this.http.post<any>('api/box/assign/checkout', assign)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  checkInAssign(assign: Assign): Observable<number> {
    return this.http.post<any>('api/box/assign/checkin', assign)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  assignLocations(assignId: number): Observable<AssignLocation[]> {
    return this.http.get<any>('api/box/assign/locations/' + assignId)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  assignAddLocation(assignId: number, locationId: string): Observable<any> {
    return this.http.get<any>('api/box/assign/location/add/' + assignId + '/' + locationId)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  assignDeleteLocation(assignLocationId: number): Observable<number> {
    return this.http.get<any>('api/box/assign/location/delete/' + assignLocationId)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }


  assignSignatures(assignId: number): Observable<Assign> {
    return this.http.get<any>('api/box/assign/signatures/' + assignId)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

}
