import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';



@Injectable()
export class HTTPErrorService {



  constructor() { }



  public HandleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      console.error(
        `Name ${error.name}, ` +
        `Status code ${error.status}, ` +
        `Status ${error.statusText}, ` +
        `Message ${error.message}, ` +
        `body was: ${error.error}`);
    }

    return throwError(error);

    // return an observable with a user-facing error message
    //return throwError(
    //  'Something bad happened; please try again later.');
  };




}
