import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HTTPErrorService } from './http-error.service';
import { PackageCountReport } from '../Models/PackageCountReport';
import { AverageSortCountReport } from '../Models/AverageSortCountReport';
import { DestructionLog } from '../Models/DestructionLog';



@Injectable()
export class DashboardService {



  constructor(private http: HttpClient,
    private httpErrorService: HTTPErrorService) { }

    getPackageCountReport(date: string): Observable<PackageCountReport[]> {
        let packageCountReport = new PackageCountReport();
        packageCountReport.Date = date;

        let bodyString = JSON.stringify(packageCountReport);

      return this.http.post<any>('api/dashboard/packagecountreport', bodyString)
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    } 

    getAverageSortCountReport(date: string): Observable<AverageSortCountReport[]> {
        let sortCountReport = new AverageSortCountReport();
        sortCountReport.Date = date;

        let bodyString = JSON.stringify(sortCountReport);

      return this.http.post<any>('api/dashboard/averagesortcountbydate', bodyString)
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

  }

  getDestructionLog(request: DestructionLog): Observable<DestructionLog[]> {

    return this.http.post<any>('api/dashboard/destructionlog', request)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );

  }

  
   
}
