import { Component, OnInit } from '@angular/core';
import { UserService } from '../../Services/user.service';
import { BoxService } from '../../Services/box.service';

import { Box } from "../../Models/Box";
import { BoxLocation } from "../../Models/BoxLocation";

import { Howl } from "Howler";


@Component({
    selector: 'astep-transfer-box',
    templateUrl: './transfer-box.component.html'
})
export class TransferBoxComponent implements OnInit {

  public processing: boolean = false;
  public barcode: string = "";

  public boxLocation: BoxLocation;
  public boxes: Box[];

  
  public message: string = "";
  public validSound: Howl;
  public invalidSound: Howl;



  constructor(public userService: UserService,
    public boxService: BoxService) {


  }

  ngOnInit() {

    this.validSound = new Howl({
      src: ['assets/sounds/ding.mp3']
    });
    this.invalidSound = new Howl({
      src: ['assets/sounds/error.mp3']
    });

  }


  onBarcodeKeyUp(x) { // appending the updated value to the variable

    //this.text += x.target.value + ' | ';

    if (!this.processing) {
      this.processing = true;
      setTimeout(() => {
        this.processing = false;
        this.readBarcode();

      }, 1500);

    }


  }

  readBarcode() {

    if (this.barcode.startsWith("Administep:")) {
      var barcode = this.barcode.replace("Administep:", "");
      let json = JSON.parse(barcode);

      if (json.boxlid) {
        this.getBoxLocation(json.boxlid);
      }
      if (json.boxNumber) {
        this.setBoxLocation(json.boxNumber);
      }
      

    }

    this.barcode = "";


  }

  getBoxLocation(locationId: string) {
    this.processing = true;
    this.boxService.getBoxLocation(locationId).subscribe(
      (boxLocation: BoxLocation) => {
        this.processing = false;

        this.boxLocation = boxLocation;
        this.getBoxesAtLocation(locationId);
        this.validSound.play();

    });
  }

  getBoxesAtLocation(locationId: string) {

    this.boxService.getBoxesAtLocation(locationId).subscribe(
      (boxes: Box[]) => {

        this.boxes = boxes;

      });
  }

  setBoxLocation(boxNum: string) {
    this.processing = true;

    this.boxService.setBoxLocation(boxNum, this.boxLocation.LocationId).subscribe(
      (response: any) => {
        this.processing = false;

        if (response.result == "") {
          this.getBoxesAtLocation(this.boxLocation.LocationId);
          this.validSound.play();
          this.message = "";
        }
        else {
          this.invalidSound.play();
          this.message =response.result;
        }

      });
  }


}
