export class DestructionLog {

  constructor(public BatchNum?: string,
    public ReceivedDate?: Date,
    public ScanDate?: Date,
    public BoxNum?: number,
    public TransferShredBinDate?: Date,
    public LocationName?: string,
    public AssignId?: number,
    public CheckOutDate?: Date,
    public VendorName?: string,
    public VendorEmpName?: string,
    public CheckInDate?: Date,
    public UserFirstName?: string,
    public UserLastName?: string,
    public CheckInDateFrom?: string,
    public CheckInDateTo?: string,
    ) { }
}

