import { Component } from '@angular/core';
import { UserService } from './Services/user.service';

@Component({
  // tslint:disable-next-line
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  todayDate: Date = new Date();


  constructor(public userService: UserService) {
  }

  logoutPressed() {
    this.userService.user = null;
    localStorage.removeItem('user');
  }

  requestFullScreen() {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      //(document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document['mozFullScreenElement'] && document['mozFullScreenElement'] !== null) ||
      (document['msFullscreenElement'] && document['msFullscreenElement'] !== null);

    var element;

    if (!isInFullScreen) {
      element = document.body; // Make the body go full screen.
    } else {
      element = document;
    }

    // Supports most browsers and their versions.
    var requestMethod;

    if (!isInFullScreen) {
      requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    } else {
      requestMethod = element.exitFullscreen || element.webkitExitFullscreen || element.mozCancelFullScreen || element.msExitFullscreen;
      console.log(requestMethod);
    }

    if (requestMethod) { // Native full screen.
      requestMethod.call(element);
    }
  }


}
