import { Component, ViewChild, OnInit  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SignaturePad } from 'angular2-signaturepad';
import { DatePipe } from "@angular/common";

//import * as jsPDF from 'jspdf'
//import "jspdf-autotable";
//declare const require: any;
//const jsPDF = require('jspdf');
//require('jspdf-autotable');
//import jsPDF from 'jspdf';




import { Assign } from '../../Models/Assign';
import { AssignLocation } from '../../Models/AssignLocation';

import { UserService } from '../../Services/user.service';
import { DashboardService } from '../../Services/dashboard.service';
import { BoxService } from '../../Services/box.service';
import { DestructionLog } from '../../Models/DestructionLog';


@Component({
  selector: 'astep-destruction-log',
  templateUrl: './destruction-log.component.html'
})
export class DestructionLogComponent implements OnInit  {

  public processing: boolean = false;
  public batchNum: string = "";
  public boxNum: number;
  public checkInDateFrom: Date = new Date();
  public checkInDateTo: Date = new Date();
  public displayLog: boolean = false;

  public logs: DestructionLog[] = [];
  public log: DestructionLog;

  public userSignBase64: string = "";
  public vendorSignBase64: string = "";
  public signProcessing: boolean = false;
  public cols: any[];
  public colsCSV: any[];
  public exportColumns: any[];

  constructor(public userService: UserService,
              public dashboardService: DashboardService,
              public boxService: BoxService,
              public domSanitizer: DomSanitizer,
              public datePipe: DatePipe) {


  }



  ngOnInit() {


    this.cols = [
      { field: "BatchNum", header: "Batch Num" },
      { field: "ReceivedDate", header: "Received Date\nScan Date" },
      { field: "BoxNum", header: "Box Num" },
      { field: "TransferShredBinDate", header: "Transfer Shred Bin Date" },
      { field: "LocationName", header: "Shred Bin" },
      { field: "CheckOutDate", header: "Check Out\nDestruction Date" },
      { field: "VendorName", header: "Vendor Name" },
      { field: "VendorEmpName", header: "Vendor Emp Name" },
      { field: "UserLastName", header: "ASTEP Employee" }
    ];

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    this.colsCSV = [
      { field: "BatchNum", header: "Batch Num" },
      { field: "ReceivedDate", header: "Received Date" },
      { field: "ScanDate", header: "Scan Date" },
      { field: "BoxNum", header: "Box Num" },
      { field: "TransferShredBinDate", header: "Transfer Shred Bin Date" },
      { field: "LocationName", header: "Shred Bin" },
      { field: "CheckOutDate", header: "Check Out" },
      { field: "CheckInDate", header: "Destruction Date" },
      { field: "VendorName", header: "Vendor Name" },
      { field: "VendorEmpName", header: "Vendor Emp Name" },
      { field: "UserLastName", header: "ASTEP Employee Last Name" },
      { field: "UserFirstName", header: "ASTEP Employee First Name" }
    ];


  }


  search() {

    if (this.batchNum == "" &&
        this.checkInDateFrom == null &&
        (this.boxNum == 0 || this.boxNum == null)) {
      alert("You must enter one search criteria.");
      return;
    }







    var request: DestructionLog = new DestructionLog();
    request.BatchNum = this.batchNum;
    if (this.boxNum > 0) {
      request.BoxNum = this.boxNum;
    }
    else {
      request.BoxNum = 0;
    }


    request.CheckInDateFrom = this.datePipe.transform(this.checkInDateFrom, 'MM/dd/yyyy');
    request.CheckInDateTo = this.datePipe.transform(this.checkInDateTo, 'MM/dd/yyyy');


    this.processing = true;
    this.dashboardService.getDestructionLog(request).subscribe(
      (logs: DestructionLog[]) => {

        this.processing = false;
        this.logs = logs;

      }, error => {
        this.processing = false;
      });
  }

  exportPdf() {

    var jsPDF = require('jspdf');
    require('jspdf-autotable');

    var doc = new jsPDF.default('l', 'pt');
    //doc.autoTable(this.exportColumns, this.logs);

    doc.text(40, 30, "Destruction Log");
    doc.autoTable({
      body: this.logs,
      columns: this.exportColumns,
      columnStyles: {
        BatchNum: { columnWidth: 100 }, 
        ReceivedDate: { columnWidth: 125 }, 
        BoxNum: { columnWidth: 45 }, 
        TransferShredBinDate: { columnWidth: 125 }, 
        CheckOutDate: { columnWidth: 125 }, 
      },
      didParseCell: (data) => {

        if (data.section == "body") {
          //console.log('data', data);
          //console.log('data.column.dataKey', data.column.dataKey);

          if (data.column.dataKey == "ReceivedDate") {
            data.cell.text = [
              this.datePipe.transform(data.row.raw.ReceivedDate, 'MMM d, y'),
              this.datePipe.transform(data.row.raw.ScanDate, 'MMM d, y, h:mm:ss a')
            ];
          }
          if (data.column.dataKey == "TransferShredBinDate") {
            data.cell.text = this.datePipe.transform(data.row.raw.TransferShredBinDate, 'MMM d, y, h:mm:ss a');
          }
          if (data.column.dataKey == "CheckOutDate") {
            data.cell.text = [
              this.datePipe.transform(data.row.raw.CheckOutDate, 'MMM d, y, h:mm:ss a'),
              this.datePipe.transform(data.row.raw.CheckInDate, 'MMM d, y, h:mm:ss a')
            ];
          }
          if (data.column.dataKey == "UserLastName") {
            data.cell.text = data.row.raw.UserLastName + ', ' + data.row.raw.UserFirstName;
          }          
    
        }
      }
    });

    doc.save('DestructionLog.pdf');


  }




  viewLog(log: DestructionLog) {
    this.log = log;
    this.displayLog = true;

    this.getAssignSignatures();
  }
  

  getAssignSignatures() {

    this.signProcessing = true;
    this.boxService.assignSignatures(this.log.AssignId).subscribe(
      (assign: Assign) => {

        this.signProcessing = false;

        this.userSignBase64 = 'data:image/png;base64,' + (this.domSanitizer.bypassSecurityTrustResourceUrl(assign.UserSignBase64) as any).changingThisBreaksApplicationSecurity;
        this.vendorSignBase64 = 'data:image/png;base64,' + (this.domSanitizer.bypassSecurityTrustResourceUrl(assign.VendorSignBase64) as any).changingThisBreaksApplicationSecurity;



      }, error => {
        this.signProcessing = false;
    });
  }

  print() {
    var divContents = document.getElementById("printContent").innerHTML;
    var a = window.open('', '', 'height=700, width=1200');
    a.document.write('<html>');
    a.document.write('<body > <h1>Certificate of Destruction<br>');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  }
}
