import { Injectable } from '@angular/core';
import { FormControl } from "@angular/forms";

@Injectable()
// Add validation methods here that can be used in different forms we use. 
export class ValidationService {

    constructor() { }

    public validateMaskedInput(c: FormControl) {
        if (c.value && c.value.indexOf("_") >= 0) {
            return {
                validateMaskedInput: {
                    valid: false
                }
            }
        } else {
            return null;
        }
    }

    validateIsValidDate(c: FormControl) {
        if (!c.value || c.value.length == 0 || c.value == '&nbsp;') {
            return null;
        }

        var date = new Date(c.value);
        if (isNaN(date.getTime())) {
            return {
                validateIsValidDate: {
                    valid: false
                }
            }
        }

        return null;
    }
}
