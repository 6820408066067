import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { HTTPErrorService } from './http-error.service';
import { UserService } from '../Services/user.service';
import { Company } from '../Models/Company';
import { LOB } from '../Models/LOB';
import { FormType } from '../Models/FormType';
import { FormCategory } from '../Models/FormCategory';
import { FormSubCategory } from '../Models/FormSubCategory';
import { Batch } from '../Models/Batch';
import { Box } from '../Models/Box';
import { Package } from '../Models/Package';

@Injectable()
export class ScanStationService {

    //get headerOptions(): Object {
    //    let headers = new Headers({ 'Content-Type': 'application/json', 'Bearer': this.userService.user.AuthToken }); // ... Set content type to JSON
    //    let options = new RequestOptions({ headers: headers }); // Create a request option
    //    return options;
    //}

  constructor(private http: HttpClient,
        private httpErrorService: HTTPErrorService,
        private userService: UserService) { }

  

    getCompanies(scanuser): Observable<Company[]> {

      return this.http.post<any>('api/company/companies/' + scanuser, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );
    }


  getLinesOfBusiness(companyId): Observable<LOB[]> {

    return this.http.post<any>('api/sort/lob/' + companyId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );

    }

  getformTypes(lobId): Observable<LOB[]> {
    return this.http.post<any>('api/sort/formtypes/' + lobId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );

    }

    getFormCategories(formTypeId): Observable<LOB[]> {
      return this.http.post<any>('api/sort/formcategories/' + formTypeId, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }

    getBatchInformation(batchId): Observable<Batch> {
      return this.http.post<any>('api/scanstation/batch/' + batchId, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }

    getFormSubCategories(categoryId): Observable<LOB[]> {
      return this.http.post<any>('api/sort/formsubcategories/' + categoryId, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }

    completeBatch(batchId, boxNumber, docCount, pageCount, scanuser, ocrDoc): Observable<any> {
      return this.http.post<any>('api/scanstation/completebatch/' + batchId + "/" + boxNumber + "/" + docCount + "/" + pageCount + "/" + scanuser + "/" + ocrDoc, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }

    rejectBatch(batchId): Observable<any> {
      return this.http.post<any>('api/scanstation/rejectbatch/' + batchId, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }

    rescanBatch(batchId): Observable<any> {
      return this.http.post<any>('api/scanstation/rescanbatch/' + batchId, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );
    }

  deleteBatch(batchId): Observable<any> {
    return this.http.post<any>('api/scanstation/deletebatch/' + batchId, "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
    }

    updateBatchBox(batchId, boxNumber): Observable<any> {
      return this.http.post<any>('api/scanstation/updatebatchbox/' + batchId + "/" + boxNumber, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );
    }

    addBatch(batch: Batch): Observable<Batch> {
      let bodyString = JSON.stringify(batch);

      return this.http.post<any>('api/sort/batch/add', bodyString)
          .pipe(
            catchError(this.httpErrorService.HandleError)
        );

    }

    getLastCreatedBoxes(companyTPNum: string): Observable<Box[]> {
      return this.http.post<any>('api/box/lastcreatedboxes/' + companyTPNum, "")
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );
  }

  getLastCreatedBoxesLOB(companyTPNum: string, lob: string): Observable<Box[]> {

    let body = {
      LineOfBusiness: lob,
    };

    return this.http.post<any>('api/box/lastcreatedboxeslob/' + companyTPNum, body)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
  }

  getAllLastCreatedBoxes(): Observable<Box[]> {
    return this.http.post<any>('api/box/lastcreatedboxes', "")
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );
    }

  getBatchHistory(batchHistoryRequest): Observable<Batch[]> {
    return this.http.post<any>('api/scanstation/batchhistory', batchHistoryRequest)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );

    }

    loadPackageInformation(trackingNumber: string): Observable<Package> {
        let pkg = new Package();
        pkg.TrackingNum = trackingNumber;

        let bodyString = JSON.stringify(pkg);


      return this.http.post<any>('api/sort/packageinformation', bodyString)
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );

    }


  uploadDocument(fileName: string, docBase64: string): Observable<any> {
    return this.http.post<any>('api/upload/' + fileName, docBase64)
      .pipe(
        catchError(this.httpErrorService.HandleError)
      );

  }



}
