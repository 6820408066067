import { Injectable } from '@angular/core';
//import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HTTPErrorService } from './http-error.service';
import { User } from '../Models/user';

@Injectable()
export class UserService {

    public user: User;

    //get headerOptions(): Object {
    //    let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
    //    let options = new RequestOptions({ headers: headers }); // Create a request option
    //    return options;
    //}

  constructor(private http: HttpClient,
    private httpErrorService: HTTPErrorService) { }


    authenticateUser(username: string, password: string): Observable<User> {

        let body = {
            UserName: username,
            Password: password
        };

      let bodyString = JSON.stringify(body); // Stringify payload

      return this.http.post<any>('api/user/authenticate', bodyString)
        .pipe(
          catchError(this.httpErrorService.HandleError)
        );


  }



}
