import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from '../../Services/user.service';
import { User } from '../../Models/user';

@Component({
    selector: 'astep-login',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    returnUrl: string;
    processing = false;

    constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) {
        this.loginForm = new FormGroup({
            Username: new FormControl('', [Validators.required, Validators.maxLength(30)]),
            Password: new FormControl('', [Validators.required, Validators.maxLength(30)])
        });
    }

    loginPressed() {
        if (this.loginForm.valid && !this.processing) {
            this.processing = true;

            this.userService.authenticateUser(this.loginForm.controls['Username'].value, this.loginForm.controls['Password'].value).subscribe((user: User) => {
                this.userService.user = user;

                localStorage.setItem('user', JSON.stringify(user));
                
                this.router.navigate(['scanstation']);
                //if (user.UserType == 'scan') {
                //} else {
                //    this.router.navigate([this.returnUrl]);
                //}
                
                this.processing = false;
            },
              error => {
                console.log('error', error);

                    if (error.status == 401) {
                        alert('Invalid username or password. Please try again.');
                    } else {
                        alert("Error connecting to server. Please try again later.");
                    }

                    this.processing = false;
                });
        }
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
}
