import { Component, ViewChild, OnInit  } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

import { Assign } from '../../Models/Assign';
import { AssignLocation } from '../../Models/AssignLocation';

import { UserService } from '../../Services/user.service';
import { BoxService } from '../../Services/box.service';


@Component({
  selector: 'astep-shred-location',
  templateUrl: './shred-location.component.html'
})
export class ShredLocationComponent implements OnInit  {

  public processing: boolean = false;
  public locationFound: boolean = false;
  public displayAssign: boolean = false;
  public displayAssignStart: boolean = false;
  public displayScanLocations: boolean = false;
  public displayVendorSignaturePad: boolean = false;
  public displayCheckedOut: boolean = false;
  public displayUserSignaturePad: boolean = false;

  public userSigned: boolean = false;
  public vendorSigned: boolean = false;

  public assigns: Assign[];
  public assign: Assign;
  public assignLocations: AssignLocation[];

  public barcode: string = "";
  
  
  @ViewChild("userSignaturePad") userSignaturePad: SignaturePad;
  @ViewChild("vendorSignaturePad") vendorSignaturePad: SignaturePad;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };


  constructor(public userService: UserService,
              public boxService: BoxService) {
  }



  ngOnInit() {
    this.getOpenAssigns();
    
  }


  getOpenAssigns() {
    this.processing = true;
    this.boxService.openAssigns().subscribe(
      (assigns: Assign[]) => {

        this.processing = false;
        this.assigns = assigns;

      });
  }


  newAssign() {
    this.displayAssign = true;
    this.displayAssignStart = true;
    this.displayScanLocations = false;
    this.displayUserSignaturePad = false;
    this.displayCheckedOut = false;
    this.displayVendorSignaturePad = false;
    this.userSigned = false;
    this.vendorSigned = false;

    this.assign = {};
    this.assign.Id = 0;
    this.assign.VendorName = localStorage.getItem("VendorName")
    this.assign.VendorEmpName = localStorage.getItem("VendorEmpName")

    this.assignLocations = null;
  }


  editAssign(assign: Assign) {

    this.displayAssign = true;
    this.displayAssignStart = false;
    this.assign = assign;

    this.displayUserSignaturePad = false;
    this.displayCheckedOut = false;
    this.displayVendorSignaturePad = false;
    this.userSigned = false;
    this.vendorSigned = false;


    if (!this.assign.CheckOutDate) {
      this.displayScanLocations = true;
    }
    else {
      this.displayCheckedOut = true;
      this.vendorSigned = true;

    }

    this.getAssignLocations();

  }

  startAssign() {
    this.displayAssign = true;
    this.displayAssignStart = true;

    localStorage.setItem("VendorName", this.assign.VendorName);
    localStorage.setItem("VendorEmpName", this.assign.VendorEmpName);


    this.processing = true;

    this.boxService.startAssign(this.assign).subscribe(
      (assignId: number) => {

        this.processing = false;
        this.assign.Id = assignId;
        this.displayAssignStart = false;
        this.displayScanLocations = true;
        this.getAssignLocations();
        this.getOpenAssigns();
      });
  }

  getAssignLocations() {

    this.processing = true;
    this.boxService.assignLocations(this.assign.Id).subscribe(
      (assignLocations: AssignLocation[]) => {
        this.processing = false;
        this.assignLocations = assignLocations;
      });
  }


  

  onBarcodeKeyUp(x) {

    if (!this.processing) {
      this.processing = true;
      setTimeout(() => {
        this.processing = false;
        this.readBarcode();

      }, 1500);

    }


  }

  readBarcode() {

    if (this.barcode.startsWith("Administep:")) {
      var barcode = this.barcode.replace("Administep:", "");
      let json = JSON.parse(barcode);

      if (json.boxlid) {
        this.assignAddLocation(json.boxlid);
      }
    }

    this.barcode = "";

  }


  assignAddLocation(locationId: string) {
    this.processing = true;

    this.boxService.assignAddLocation(this.assign.Id, locationId).subscribe(
      (response: any) => {

        this.processing = false;

        if (response.result == "") {
          this.getAssignLocations();
        }
        else {
          alert(response.result);
        }

      });
  }



  assignDeleteLocation(assignLocationId: number) {
    this.processing = true;
    this.boxService.assignDeleteLocation(assignLocationId).subscribe(
      (response: any) => {

        this.processing = false;
        this.getAssignLocations();

      });
  }


  startVendorSign() {
    this.displayScanLocations = false;
    this.displayVendorSignaturePad = true;
  }
  
  checkOut() {

    this.assign.VendorSignBase64 = this.vendorSignaturePad.toDataURL();

    this.processing = true;
    this.boxService.checkOutAssign(this.assign).subscribe(
      (assign: Assign) => {
        this.processing = false;
        this.assign.CheckOutDate = assign.CheckOutDate;
        this.assign.VendorSignBase64 = "";

        this.displayVendorSignaturePad = false;
        this.displayCheckedOut = true;
      });



  }

  startUserSign() {
    this.displayCheckedOut = false;
    this.displayUserSignaturePad = true;
  }


  checkIn() {

    this.assign.UserSignBase64 = this.userSignaturePad.toDataURL();
    this.processing = true;

    this.boxService.checkInAssign(this.assign).subscribe(
      (assignId: number) => {
        this.processing = false;

        this.displayAssign = false;
        this.displayAssignStart = false;
        this.displayScanLocations = false;
        this.displayUserSignaturePad = false;
        this.displayCheckedOut = false;
        this.displayVendorSignaturePad = false;
        this.userSigned = false;
        this.vendorSigned = false;

        this.getOpenAssigns();

      });

  }


  userSignComplete() {
    this.userSigned = true;
  }

  userVendorComplete() {
    this.vendorSigned = true;
  }

  


}
