import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';  // <-- #1 import modul
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
//import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from "@angular/common";

import { AppComponent } from './app.component';
import { MainComponent } from './Components/main/main.component';
import { LoginComponent } from './Components/login/login.component';
import { TransferBoxComponent } from './Components/transfer-box/transfer-box.component';
import { ShredLocationComponent } from './Components/shred-location/shred-location.component';
import { DestructionLogComponent } from './Components/destruction-log/destruction-log.component';

import { NumbersOnly } from './Directives/numbers-only.directive';

import { TokenInterceptor } from './Interceptors/token.interceptor';
import { HTTPErrorService } from './Services/http-error.service';
import { UserService } from './Services/user.service';
import { ValidationService } from "./Services/validation.service";
import { ScanStationService } from "./Services/scanstation.service";
import { DashboardService } from "./Services/dashboard.service";
import { ScannerSettingsService } from "./Services/scanner-settings.service";
import { AuthGuard } from './Guards/auth.guard';
import { BoxService } from './Services/box.service';



//import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    TransferBoxComponent,
    ShredLocationComponent,
    DestructionLogComponent,
    NumbersOnly,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    //ChartsModule,
    ToastModule,
    DialogModule,
    CalendarModule,
    TableModule,
    SignaturePadModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe,
    AuthGuard,
    HTTPErrorService,
    UserService,
    ValidationService,
    ScanStationService,
    DashboardService,
    ScannerSettingsService,
    BoxService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
