import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../Services/user.service';
import { User } from '../Models/user';

@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router, private userService: UserService) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('user')) {
            try {
                let currentUser: User = JSON.parse(localStorage.getItem('user'));

                if (currentUser.AuthToken.length > 0) {

                    this.userService.user = currentUser;

                    if (currentUser.UserType == 'scan' && state.url == '/') {
                        this.router.navigate(['/scanstation']);
                    }

                    return true;
                }
            } catch (e) {

            }
        }
 
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}