export class AverageSortCountReport {

    constructor(
        public FirstName?: string,
        public LastName?: number,
        public Date?: string,
        public Duration?: Number,
        public Average?: Number,
        public Total?: Number
    ) { }
}